<template>
  <CFooter :fixed="false">
    <div>
      Kaspi Pay
      <span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1">Разработано в</span>
      <a
        href="https://ru.api-soft.com"
        target="_blank"
      >APISOFT</a>
    </div>
  </CFooter>
</template>

<script>
(function(w,d,u){
  var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);
  var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
})(window,document,'https://cdn-ru.bitrix24.ru/b22291182/crm/site_button/loader_4_3c4vhj.js');

export default {
  name: 'TheFooter',
};
</script>
