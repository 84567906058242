<template>
  <CHeader
    fixed
    with-subheader
    light
  >
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderNav class="d-down-none mr-auto">
      <CHeaderNavItem class="px-4">
        <CHeaderNavLink to="/">
          <template v-if="$store.getters.isAdmin">
            Личный кабинет модуля Kaspi Pay
          </template>
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-down-none mx-2">
        <div>
          {{ $store.state.auth.user.email }}
        </div>
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt.vue';

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
  },
};
</script>
